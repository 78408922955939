// @ts-nocheck
export const CoreEnumsAccountNotificationSubscriptionEnum = {
  SubscriptionCreated: 'SubscriptionCreated',
  SubscriptionCanceled: 'SubscriptionCanceled',
  SubscriptionResubscribed: 'SubscriptionResubscribed',
  ClubCreated: 'ClubCreated',
  DataCollectionCreated: 'DataCollectionCreated',
  DataCollectionDeleted: 'DataCollectionDeleted',
  ClubsWithoutRegion: 'ClubsWithoutRegion',
} as const
export type CoreEnumsAccountNotificationSubscriptionEnum =
  (typeof CoreEnumsAccountNotificationSubscriptionEnum)[keyof typeof CoreEnumsAccountNotificationSubscriptionEnum]
