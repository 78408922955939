import clsx from 'clsx'
import styles from './badge.module.css'

type Theme = 'blue' | 'green' | 'grey'

interface Props {
  children: React.ReactNode
  theme: Theme
}

export function Badge({ children, theme }: Props) {
  return <div className={clsx(styles.badge, styles[theme])}>{children}</div>
}
