import { IconButton } from '@/components/ui/icon-button'
import { X } from '@phosphor-icons/react/dist/ssr'
import { Flex } from '@radix-ui/themes'
import clsx from 'clsx'
import { cva, VariantProps } from 'cva'
import * as React from 'react'
import {
  DialogProps,
  Heading,
  Modal,
  ModalOverlay,
  ModalOverlayProps,
  OverlayTriggerStateContext,
  Dialog as RACDialog,
  DialogTrigger as RACDialogTrigger,
} from 'react-aria-components'
import styles from './dialog.module.css'

const dialogVariants = cva({
  base: styles.dialog,
  variants: {
    size: {
      sm: styles.sizeSm,
      rg: styles.sizeRg,
      md: styles.sizeMd,
      lg: styles.sizeLg,
      xl: styles.sizeXl,
    },
  },
})

interface Props extends DialogProps, VariantProps<typeof dialogVariants> {}

export const DialogTrigger = RACDialogTrigger

export function DialogModal({
  isKeyboardDismissDisabled,
  ...props
}: ModalOverlayProps) {
  return (
    <ModalOverlay
      className={styles.modalOverlay}
      isKeyboardDismissDisabled={isKeyboardDismissDisabled}
    >
      <Modal className={styles.modal} {...props} />
    </ModalOverlay>
  )
}

export function Dialog({ className, size, ...props }: Props) {
  return (
    <RACDialog
      className={clsx(dialogVariants({ size }), className)}
      {...props}
    />
  )
}

export function DialogHeader({
  children,
  isDismissable = true,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  isDismissable?: boolean
}) {
  return (
    <Flex
      className={styles.dialogHeader}
      justify="between"
      align="center"
      {...props}
    >
      <div style={{ width: '2rem' }}></div>
      <Heading level={2} className="headline-sm text-center" slot="title">
        {children}
      </Heading>

      <div>{isDismissable && <CloseButton />}</div>
    </Flex>
  )
}

export function DialogContent({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return <div className={clsx(styles.dialogContent, className)} {...props} />
}

export function DialogFooter({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return <div className={clsx(styles.dialogFooter, className)} {...props} />
}

export function CloseButton() {
  const state = React.useContext(OverlayTriggerStateContext)!

  return (
    <IconButton
      aria-label="Close"
      variant="ghost"
      onPress={() => state.close()}
    >
      <X width="20" height="20" aria-hidden="true" />
    </IconButton>
  )
}
