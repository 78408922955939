import { useContextsMe } from '@/hooks/contexts/useContextsMe'
import { useContextStore } from '@/providers/context-store-provider'
import { useMemo } from 'react'

export function useActiveAccount() {
  const { accountId, directAccessAccountId } = useContextStore((state) => state)
  const { data: contextsMe } = useContextsMe()
  const accounts = useMemo(() => contextsMe?.accounts ?? [], [contextsMe])

  const activeAccount = useMemo(() => {
    return accounts.find(
      (account) =>
        account.accountId === accountId &&
        (!directAccessAccountId ||
          account.directAccessAccountId === directAccessAccountId),
    )
  }, [accountId, accounts, directAccessAccountId])

  const directAccessAccount = useMemo(() => {
    if (!activeAccount?.directAccessAccountId) {
      return activeAccount
    }
    return accounts.find(
      (account) => account.accountId === activeAccount.directAccessAccountId,
    )
  }, [accounts, activeAccount])

  const indirectAccessAccount = useMemo(() => {
    if (!activeAccount?.directAccessAccountId) {
      return null
    }
    return activeAccount
  }, [activeAccount])

  return {
    activeAccount,
    directAccessAccount,
    indirectAccessAccount,
  }
}
