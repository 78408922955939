import toast from 'react-hot-toast'

export const notifySuccess = (message: string) => {
  return toast.success(() => message, {
    icon: null,
  })
}

export const notifyError = (message: string) => {
  return toast.error(() => message, {
    icon: null,
  })
}
