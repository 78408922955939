import type { CoreDtOsAccountAccountSurveyOverviewDtoSurvey } from '@/api/models'
import { lcFirst } from '@/utils'
import { getTranslation } from '@/utils/translation'
import { useLocale, useTranslations } from 'next-intl'

export function useSurveyTranslation() {
  const generatedT = useTranslations('generated')
  const locale = useLocale()

  const translator = (
    survey: CoreDtOsAccountAccountSurveyOverviewDtoSurvey | undefined,
  ) => {
    if (!survey) {
      return {
        surveyName: '',
        surveyDescription: '',
      }
    }

    // Use survey language for translations.
    // const surveyLangCode = getDefaultSurveyLanguage(survey)

    // Use translation if defined and fallback to Locize default text.
    const surveyName = survey.accountSurveyName
      ? getTranslation(survey.accountSurveyName)
      : // @ts-expect-error
        generatedT(`surveys.${locale}.${lcFirst(survey.surveyType)}.surveyName`)

    const surveyDescription = survey.accountSurveyDescription
      ? getTranslation(survey.accountSurveyDescription)
      : generatedT(
          // @ts-expect-error
          `surveys.${locale}.${lcFirst(survey.surveyType)}.surveyDescription`,
        )

    return {
      surveyName,
      surveyDescription,
    }
  }

  return translator
}
