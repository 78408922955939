// @ts-nocheck
export const CoreEnumsGenericModuleType = {
  TableTotal: 'TableTotal',
  TableResult: 'TableResult',
  TableRaw: 'TableRaw',
  KPIBoxSmall: 'KPIBoxSmall',
  BarchartVerticalFancy: 'BarchartVerticalFancy',
  BarchartHorizontalFancy: 'BarchartHorizontalFancy',
  TablePie: 'TablePie',
  SubTableRaw: 'SubTableRaw',
  SubBarchartVerticalFancy: 'SubBarchartVerticalFancy',
  PiechartSmall: 'PiechartSmall',
  SubPiechartSmall: 'SubPiechartSmall',
  StackedBarchart: 'StackedBarchart',
} as const
export type CoreEnumsGenericModuleType =
  (typeof CoreEnumsGenericModuleType)[keyof typeof CoreEnumsGenericModuleType]
