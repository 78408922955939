import { Button } from '@/components/ui/button'
import { Popover, PopoverTrigger } from '@/components/ui/popover'
import { SearchField } from '@/components/ui/search-field'
import { useDateFormatter } from '@/hooks/i18n/useDateFormatter'
import { isNilOrEmpty } from '@/utils/isNilOrEmpty'
import { Funnel } from '@phosphor-icons/react/dist/ssr'
import { Flex } from '@radix-ui/themes'
import type { Column, Table } from '@tanstack/react-table'
import clsx from 'clsx'
import { parseISO } from 'date-fns'
import { useTranslations } from 'next-intl'
import { useMemo, useState } from 'react'
import styles from './table-column-filter.module.css'

interface Props {
  column: Column<any, unknown>
  table: Table<any>
}

export function TableColumnFilter({ column, table }: Props) {
  const t = useTranslations('common')
  const dateFormatter = useDateFormatter('dayMonthYear', 'utc')
  const [searchQuery, setSearchQuery] = useState('')
  const isDate = column.columnDef.meta?.type === 'date'
  const columnFilterValue = (column.getFilterValue() ?? []) as string[]

  const uniqueValues = useMemo(() => {
    const allRows = table.getPreFilteredRowModel().flatRows
    const uniqueValueMap = new Map()
    allRows.forEach((row) => {
      const value = row.getValue(column.id) as string
      const formattedValue =
        isDate && !isNilOrEmpty(value)
          ? dateFormatter.format(parseISO(value))
          : value
      if (formattedValue !== '') {
        uniqueValueMap.set(formattedValue, formattedValue)
      }
    })
    return Array.from(uniqueValueMap)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column.id, table.getPreFilteredRowModel()])

  const sortedFilteredValues = useMemo(
    () => Array.from(column.getFacetedUniqueValues().keys()).sort(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [column.getFacetedUniqueValues()],
  )

  const resetSearchHandler = () => {
    setSearchQuery('')
  }

  const toggleFilterOption = (value: string) => {
    const updatedFilterValue = columnFilterValue.includes(value)
      ? columnFilterValue.filter((item) => item !== value)
      : [...columnFilterValue, value]
    column.setFilterValue(updatedFilterValue)
  }

  const selectAllFilterOptions = () => {
    column.setFilterValue(sortedFilteredValues)
  }

  const resetFilterOptions = () => {
    column.setFilterValue([])
  }

  if (uniqueValues.length < 2) {
    return null
  }

  return (
    <PopoverTrigger>
      <Button
        className={clsx('transition-colors', styles.iconButton)}
        variant="unstyled"
      >
        <Funnel size={14} weight="fill" />
      </Button>
      <Popover>
        <div className={styles.container}>
          <div className={styles.searchWrapper}>
            <SearchField
              autoFocus
              aria-label={t('searchPlaceholder')}
              placeholder={t('searchPlaceholder')}
              value={searchQuery}
              onChange={setSearchQuery}
              onClear={resetSearchHandler}
            />
          </div>

          <div className={styles.optionsWrapper}>
            {!sortedFilteredValues.length && (
              <div className={styles.noResult}>Sorry, no matching results…</div>
            )}

            {sortedFilteredValues.map((option, index) => {
              const formattedTitle =
                isDate && !isNilOrEmpty(option)
                  ? dateFormatter.format(parseISO(option))
                  : (option as string)
              const isChecked = columnFilterValue.includes(option)

              if (
                formattedTitle == null ||
                !formattedTitle
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
              ) {
                return null
              }

              return (
                <label key={index} className={styles.option}>
                  <input
                    className={styles.checkbox}
                    checked={isChecked}
                    type="checkbox"
                    value={option}
                    onChange={() => toggleFilterOption(option)}
                  />
                  <div className={styles.optionText} title={formattedTitle}>
                    {formattedTitle}
                  </div>
                </label>
              )
            })}
          </div>

          <Flex justify="end" gap="2" className={styles.buttonWrapper}>
            <Button variant="ghost" onPress={resetFilterOptions}>
              Reset
            </Button>
            <Button variant="secondary" onPress={selectAllFilterOptions}>
              Select all
            </Button>
          </Flex>
        </div>
      </Popover>
    </PopoverTrigger>
  )
}
