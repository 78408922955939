import { ucFirst } from '@/utils'
import clsx from 'clsx'
import styles from './section.module.css'

interface SectionContentProps {
  children?: React.ReactNode
  title?: string
}

interface SectionProps {
  children: React.ReactNode
  contentWidth?: 'sm' | 'md' | 'lg'
  footer?: React.ReactNode
  id?: string
  noPadding?: boolean
  text?: string
  title?: string
}

export function SectionContent({ title, children }: SectionContentProps) {
  return (
    <div className={styles.contentWrapper}>
      <h3 className={styles.contentTitle}>{title}</h3>
      <div className={styles.contentText}>{children}</div>
    </div>
  )
}

export function Section({
  id,
  title,
  text,
  footer,
  children,
  noPadding = false,
  contentWidth,
  ...props
}: SectionProps) {
  return (
    <div
      className={clsx(styles.wrapper, !noPadding && styles.wrapperWithPadding)}
      id={id}
      {...props}
    >
      <div className={styles.inner}>
        {title != null && (
          <div
            className={clsx(
              styles.header,
              !noPadding && styles.headerWithPadding,
            )}
          >
            <div className={styles.headerInner}>{title}</div>
          </div>
        )}

        {text && (
          <div
            className={clsx(styles.text, 'text-3')}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}

        <div
          className={clsx(
            styles.content,
            contentWidth && styles[`content${ucFirst(contentWidth)}`],
            !noPadding && styles.contentWithPadding,
          )}
        >
          {children}
        </div>

        {footer != null && (
          <div
            className={clsx(
              styles.footer,
              !noPadding && styles.footerWithPadding,
            )}
          >
            <div className={styles.footerInner}>{footer}</div>
          </div>
        )}
      </div>
    </div>
  )
}
