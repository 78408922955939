'use client'

import { clsx } from 'clsx'
import type { VariantProps } from 'cva'
import type { LinkProps } from 'react-aria-components'
import { Link } from 'react-aria-components'
import { buttonVariants } from './button'
import styles from './button.module.css'

interface Props extends LinkProps, VariantProps<typeof buttonVariants> {}

export function LinkButton({ variant, ...props }: Props) {
  return (
    <Link
      {...props}
      className={clsx(styles.button, buttonVariants({ variant }))}
    />
  )
}
