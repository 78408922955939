import useTimeZone from '@/hooks/i18n/useTimeZone'
import type { DateTimePreset } from '@/i18n/formats'
import { getDateFormatter } from '@/utils/getDateFormatter'
import { useLocale } from 'next-intl'

export function useDateFormatter(preset?: DateTimePreset, timeZone?: string) {
  const locale = useLocale()
  const accountTimeZone = useTimeZone()
  return getDateFormatter({
    locale,
    preset,
    timeZone: timeZone ?? accountTimeZone,
  })
}
