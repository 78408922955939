import { DateTimeFormatOptions, Formats, NumberFormatOptions } from 'next-intl'

const dateTime = {
  dayMonth: {
    day: '2-digit',
    month: 'short',
  },

  dayMonthYear: {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  },

  dayMonthYearNumeric: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  },

  dayOfWeek: {
    weekday: 'long',
  },

  month: {
    month: 'short',
  },

  monthLong: {
    month: 'long',
  },

  monthYear: {
    month: 'short',
    year: 'numeric',
  },

  monthYearLong: {
    month: 'long',
    year: 'numeric',
  },

  dayMonthYearWithTime: {
    minute: 'numeric',
    hour: 'numeric',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  },
} as const

export type DateTimePreset = keyof typeof dateTime

export const formats: Partial<Formats> = {
  dateTime: dateTime as Record<string, DateTimeFormatOptions>,
  number: {} as Record<string, NumberFormatOptions>,
  list: {} as Record<string, Intl.ListFormatOptions>,
} as const
