// @ts-nocheck
export const CoreEnumsBiBookingTypeEnum = {
  Unknown: 'Unknown',
  Member: 'Member',
  Guest: 'Guest',
  Tournament: 'Tournament',
  Blocked: 'Blocked',
  Other: 'Other',
  Unidentified: 'Unidentified',
  TournamentOrBlocked: 'TournamentOrBlocked',
  Customer: 'Customer',
} as const
export type CoreEnumsBiBookingTypeEnum =
  (typeof CoreEnumsBiBookingTypeEnum)[keyof typeof CoreEnumsBiBookingTypeEnum]
