// @ts-nocheck
export const CoreEntitiesIntegrationsSurveyIntegrationTypeEnum = {
  Booking: 'Booking',
  Member: 'Member',
  Entry: 'Entry',
  Exit: 'Exit',
  Tournament: 'Tournament',
  Financial: 'Financial',
  Other: 'Other',
} as const
export type CoreEntitiesIntegrationsSurveyIntegrationTypeEnum =
  (typeof CoreEntitiesIntegrationsSurveyIntegrationTypeEnum)[keyof typeof CoreEntitiesIntegrationsSurveyIntegrationTypeEnum]
