// @ts-nocheck
export const CoreEnumsGenericDataType = {
  Integer: 'Integer',
  Decimal: 'Decimal',
  DateTime: 'DateTime',
  Boolean: 'Boolean',
  Currency: 'Currency',
  String: 'String',
  Percentage: 'Percentage',
} as const
export type CoreEnumsGenericDataType =
  (typeof CoreEnumsGenericDataType)[keyof typeof CoreEnumsGenericDataType]
