// @ts-nocheck
export const CoreDtOsBiCalculationModelEnum = {
  Count: 'Count',
  Utilization: 'Utilization',
  TeeTimeBookingRate: 'TeeTimeBookingRate',
  PlayersPrBookedTeeTime: 'PlayersPrBookedTeeTime',
  PlayersPrTeeTime: 'PlayersPrTeeTime',
} as const
export type CoreDtOsBiCalculationModelEnum =
  (typeof CoreDtOsBiCalculationModelEnum)[keyof typeof CoreDtOsBiCalculationModelEnum]
