import clsx from 'clsx'
import type { ListBoxItemProps, ListBoxProps } from 'react-aria-components'
import {
  ListBox as RACListBox,
  ListBoxItem as RACListBoxItem,
} from 'react-aria-components'
import styles from './list-box.module.css'

export function ListBox<T extends object>({
  className,
  ...props
}: ListBoxProps<T>) {
  return <RACListBox {...props} className={clsx(className, styles.listBox)} />
}

export function ListBoxItem({ className, ...props }: ListBoxItemProps) {
  return (
    <RACListBoxItem
      {...props}
      className={clsx(className, styles.listBoxItem)}
    />
  )
}
