import { useGetV1ContextsMe } from '@/api/client-hooks'
import type { CoreDtOsAccountMeDto } from '@/api/models'
import { useSession } from 'next-auth/react'

const select = (data: CoreDtOsAccountMeDto) => {
  const accounts = (data?.accounts ?? []).sort((a, b) => {
    const aName = a.name ?? ''
    const bName = b.name ?? ''
    if (aName < bName) {
      return -1
    }
    if (aName > bName) {
      return 1
    }
    return 0
  })

  return { ...data, accounts }
}

export function useContextsMe() {
  const sessionQuery = useSession()
  const accessToken = sessionQuery.data?.accessToken
  const enabled = Boolean(accessToken)

  return useGetV1ContextsMe({ query: { enabled, select } })
}
