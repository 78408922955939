// @ts-nocheck
export const CoreEntitiesSurveyDataCollectionStatusEnum = {
  Draft: 'Draft',
  Ongoing: 'Ongoing',
  Completed: 'Completed',
  Scheduled: 'Scheduled',
  Cancelled: 'Cancelled',
} as const
export type CoreEntitiesSurveyDataCollectionStatusEnum =
  (typeof CoreEntitiesSurveyDataCollectionStatusEnum)[keyof typeof CoreEntitiesSurveyDataCollectionStatusEnum]
