import type { VariantProps } from 'cva'
import { compose, cva } from 'cva'
import type { ButtonProps } from 'react-aria-components'
import { Button } from 'react-aria-components'
import { buttonVariants } from './button'
import styles from './icon-button.module.css'

const iconButtonVariants = cva({
  base: styles.iconButton,
  variants: {
    shape: {
      square: styles.shapeSquare,
      circle: styles.shapeCircle,
    },
    size: {
      sm: styles.sizeSm,
      md: styles.sizeMd,
      lg: styles.sizeLg,
      xl: styles.sizeXl,
    },
  },
  defaultVariants: {
    shape: 'square',
    size: 'md',
  },
})

interface ButtonVariantsProps extends VariantProps<typeof buttonVariants> {}
interface IconButtonVariantsProps
  extends VariantProps<typeof iconButtonVariants> {}

export interface IconButtonProps
  extends ButtonProps,
    ButtonVariantsProps,
    IconButtonVariantsProps {
  children?: React.ReactNode
}

const combinedVariants = compose(buttonVariants, iconButtonVariants)

export function IconButton({
  className,
  shape,
  size,
  variant,
  ...props
}: IconButtonProps) {
  return (
    <Button
      className={combinedVariants({ className, shape, size, variant })}
      {...props}
    />
  )
}
