// @ts-nocheck
export const CoreEntitiesSurveyConditionOperatorType = {
  GreaterThan: 'GreaterThan',
  LessThan: 'LessThan',
  EqualTo: 'EqualTo',
  NotEqualTo: 'NotEqualTo',
  In: 'In',
  NotIn: 'NotIn',
  Containing: 'Containing',
  NotContaining: 'NotContaining',
} as const
export type CoreEntitiesSurveyConditionOperatorType =
  (typeof CoreEntitiesSurveyConditionOperatorType)[keyof typeof CoreEntitiesSurveyConditionOperatorType]
