// @ts-nocheck
export const CoreDtOsResultsTimeViewEnum = {
  Day: 'Day',
  Week: 'Week',
  Month: 'Month',
  Year: 'Year',
  UnknownTimeViewType: 'UnknownTimeViewType',
} as const
export type CoreDtOsResultsTimeViewEnum =
  (typeof CoreDtOsResultsTimeViewEnum)[keyof typeof CoreDtOsResultsTimeViewEnum]
