import type { VariantProps } from 'cva'
import { cva } from 'cva'
import styles from './spinner.module.css'

const spinnerVariants = cva({
  base: styles.spinner,
  variants: {
    size: {
      sm: styles.sizeSm,
      md: styles.sizeMd,
      lg: styles.sizeLg,
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

interface Props extends VariantProps<typeof spinnerVariants> {}

export function Spinner({ size, ...props }: Props) {
  return (
    <div {...props} className={spinnerVariants({ size })}>
      <span className={styles.spinnerLeaf} />
      <span className={styles.spinnerLeaf} />
      <span className={styles.spinnerLeaf} />
      <span className={styles.spinnerLeaf} />
      <span className={styles.spinnerLeaf} />
      <span className={styles.spinnerLeaf} />
      <span className={styles.spinnerLeaf} />
      <span className={styles.spinnerLeaf} />
    </div>
  )
}
