// @ts-nocheck
export const CoreEntitiesSurveyQuarantinePeriodEnum = {
  SevenDays: 'SevenDays',
  FourteenDays: 'FourteenDays',
  ThirtyDays: 'ThirtyDays',
  ThreeMonths: 'ThreeMonths',
  SixMonths: 'SixMonths',
} as const
export type CoreEntitiesSurveyQuarantinePeriodEnum =
  (typeof CoreEntitiesSurveyQuarantinePeriodEnum)[keyof typeof CoreEntitiesSurveyQuarantinePeriodEnum]
