// @ts-nocheck
export const CoreEntitiesAccountMaoAccountListEnum = {
  AccountProfileSettings: 'AccountProfileSettings',
  TeamSettings: 'TeamSettings',
  SubscriptionOverview: 'SubscriptionOverview',
  BillingInfoOverview: 'BillingInfoOverview',
  AutomatedDataCollections: 'AutomatedDataCollections',
} as const
export type CoreEntitiesAccountMaoAccountListEnum =
  (typeof CoreEntitiesAccountMaoAccountListEnum)[keyof typeof CoreEntitiesAccountMaoAccountListEnum]
