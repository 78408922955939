import { useGetV1IntegrationsProviderid } from '@/api/client-hooks'
import type { CoreEntitiesIntegrationsSurveyIntegrationProviderEnum } from '@/api/models'
import { useEnabled } from '@/hooks/useEnabled'

export default function useIntegration(
  providerId: CoreEntitiesIntegrationsSurveyIntegrationProviderEnum,
) {
  const enabled = useEnabled()
  return useGetV1IntegrationsProviderid(providerId, undefined, {
    query: { enabled },
  })
}
