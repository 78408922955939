import {
  CoreEntitiesAccountMaoAccountListEnum,
  CoreEntitiesIntegrationsSurveyIntegrationProviderEnum,
} from '@/api/models'
import {
  CommentAmbassadorTypeEnum,
  CommentConsentTypeEnum,
  DatePresetEnum,
} from '@/lib/types'
import { formatISO, startOfMonth, startOfYear, subYears } from 'date-fns'
import { createNavigationConfig } from 'next-safe-navigation'
import { z } from 'zod'

export const cxmFromDateDefault = formatISO(startOfYear(new Date()), {
  representation: 'date',
})
export const cxmToDateDefault = formatISO(new Date(), {
  representation: 'date',
})

const tagNavigationSearchSchema = z.object({
  accountId: z.string().optional().default(''),
  tagGroupId: z.string().optional().default(''),
  tagId: z.string().optional().default(''),
})

const cxmPeriodeSearchSchema = z.object({
  fromDate: z
    .string()
    .date()
    .default(formatISO(startOfYear(new Date()), { representation: 'date' })),
  toDate: z
    .string()
    .date()
    .default(formatISO(new Date(), { representation: 'date' })),
})

export const cxmAssistantSearchSchema = z
  .object({})
  .merge(cxmPeriodeSearchSchema)
  .default({})

export const cxmCommentsSearchSchema = z
  .object({
    ambassadorTypes: z
      .nativeEnum(CommentAmbassadorTypeEnum)
      .array()
      .optional()
      .default([]),
    consentTypes: z
      .nativeEnum(CommentConsentTypeEnum)
      .array()
      .optional()
      .default([]),
    query: z.string().optional().default(''),
    take: z.coerce.number().min(1).max(100).optional().default(25),
    skip: z.coerce.number().optional().default(0),
    unreadOnly: z.coerce.boolean().optional().default(false),
    accountId: z.string().optional().default(''),
    tagGroupId: z.string().optional().default(''),
    tagId: z.string().optional().default(''),
  })
  .merge(cxmPeriodeSearchSchema)
  .merge(tagNavigationSearchSchema)
  .default({})

export const cxmListsSearchSchema = z
  .object({})
  .merge(cxmPeriodeSearchSchema)
  .default({})

export const cxmResultsSearchSchema = z
  .object({
    benchmarkId: z.string().optional(),
    preset: z
      .nativeEnum(DatePresetEnum)
      .optional()
      .default(DatePresetEnum.ThisYear),
  })
  .merge(tagNavigationSearchSchema)
  .merge(cxmPeriodeSearchSchema)
  .default({})

const biBaseSearchSchema = z.object({
  benchmarkId: z.string().optional(),
  toDate: z
    .string()
    .date()
    .default(formatISO(new Date(), { representation: 'date' })),
})

const biMinDate = subYears(new Date(2018, 0), 100)

export const biBookingsSearchSchema = biBaseSearchSchema
  .extend({
    fromDate: z
      .string()
      .date()
      .default(formatISO(startOfMonth(new Date()), { representation: 'date' }))
      .refine((startDate) => new Date(startDate) >= biMinDate, {
        message: `From date must be after ${formatISO(biMinDate, { representation: 'date' })}`,
      }),
    preset: z.nativeEnum(DatePresetEnum).default(DatePresetEnum.ThisMonth),
  })
  .merge(tagNavigationSearchSchema)
  .default({})

export const biMembersSearchSchema = biBaseSearchSchema
  .extend({
    fromDate: z
      .string()
      .date()
      .default(formatISO(startOfYear(new Date()), { representation: 'date' })),
    preset: z.nativeEnum(DatePresetEnum).default(DatePresetEnum.ThisYearToDate),
  })
  .merge(tagNavigationSearchSchema)
  .default({})

export const { routes, useSafeParams, useSafeSearchParams } =
  createNavigationConfig((defineRoute) => ({
    invites: defineRoute('/invites/[inviteId]', {
      params: z.object({
        inviteId: z.string(),
      }),
    }),
    signIn: defineRoute('/sign-in'),
    signUp: defineRoute('/sign-up/[slug]', {
      params: z.object({
        slug: z.string(),
      }),
    }),
    //
    home: defineRoute('/'),
    //
    biGenericDashboardHome: defineRoute('/bi/[type]/[accountMetricId]', {
      params: z.object({
        accountMetricId: z.string(),
        type: z.string(),
      }),
    }),
    biGenericDashboard: defineRoute(
      '/bi/[type]/[accountMetricId]/[viewGroupId]',
      {
        params: z.object({
          accountMetricId: z.string(),
          type: z.string(),
          viewGroupId: z.string(),
        }),
      },
    ),
    biGenericInputHome: defineRoute(
      '/bi/[type]/[accountMetricId]/input/[dataEntryId]',
      {
        params: z.object({
          accountMetricId: z.string(),
          dataEntryId: z.string(),
          type: z.string(),
        }),
      },
    ),
    biGenericInput: defineRoute(
      '/bi/[type]/[accountMetricId]/input/[dataEntryId]/[inputGroupId]',
      {
        params: z.object({
          accountMetricId: z.string(),
          dataEntryId: z.string(),
          inputGroupId: z.string().optional(),
          type: z.string(),
        }),
      },
    ),
    biGenericOverview: defineRoute('/bi/[type]/[accountMetricId]/overview', {
      params: z.object({
        accountMetricId: z.string(),
        type: z.string(),
      }),
    }),
    biBookingsOverview: defineRoute('/bi/bookings', {
      search: biBookingsSearchSchema,
    }),
    biBookingsDetails: defineRoute('/bi/bookings/[calculationModel]', {
      params: z.object({
        calculationModel: z.string(),
      }),
      search: biBookingsSearchSchema,
    }),
    biMembersOverview: defineRoute('/bi/members', {
      search: biMembersSearchSchema,
    }),
    biMembersDetails: defineRoute('/bi/members/[membershipRequestType]', {
      params: z.object({
        membershipRequestType: z.string(),
      }),
      search: biMembersSearchSchema,
    }),
    //
    createTournament: defineRoute('/create-tournament'),
    //
    cxmAssistant: defineRoute('/cxm/assistant/[surveyId]', {
      params: z.object({
        surveyId: z.string(),
      }),
      search: cxmAssistantSearchSchema,
    }),
    cxmComments: defineRoute('/cxm/comments/[surveyId]', {
      params: z.object({
        surveyId: z.string(),
      }),
      search: cxmCommentsSearchSchema,
    }),
    cxmLists: defineRoute('/cxm/lists/[surveyId]', {
      params: z.object({
        surveyId: z.string(),
      }),
      search: cxmListsSearchSchema,
    }),
    cxmResults: defineRoute('/cxm/results/[surveyId]', {
      params: z.object({
        surveyId: z.string(),
      }),
      search: cxmResultsSearchSchema,
    }),
    //
    profile: defineRoute('/profile'),
    //
    settingsAccount: defineRoute('/settings/account'),
    settingsClubs: defineRoute('/settings/account/clubs', {
      search: z
        .object({
          create: z.coerce.boolean().default(false),
          listType: z
            .nativeEnum(CoreEntitiesAccountMaoAccountListEnum)
            .default(
              CoreEntitiesAccountMaoAccountListEnum.AccountProfileSettings,
            ),
        })
        .default({}),
    }),
    settingsIntegrations: defineRoute('/settings/account/integrations'),
    settingsIntegrationProvider: defineRoute(
      '/settings/account/integrations/[providerId]',
      {
        params: z.object({
          providerId: z.nativeEnum(
            CoreEntitiesIntegrationsSurveyIntegrationProviderEnum,
          ),
        }),
      },
    ),
    settingsNotifications: defineRoute('/settings/account/notifications'),
    settingsSubscriptions: defineRoute('/settings/account/subscriptions'),
    settingsTeam: defineRoute('/settings/account/team'),
    settingsBi: defineRoute('/settings/bi'),
    settingsCxm: defineRoute('/settings/cxm'),
    settingsSurvey: defineRoute('/settings/cxm/[surveyId]', {
      params: z.object({
        surveyId: z.string(),
      }),
    }),
    settingsSurveyAutomatedDataCollection: defineRoute(
      '/settings/cxm/[surveyId]/automated-data-collection',
      {
        params: z.object({
          surveyId: z.string(),
        }),
      },
    ),
    settingsSurveyCustomBenchmarks: defineRoute(
      '/settings/cxm/[surveyId]/custom-benchmarks',
      {
        params: z.object({
          surveyId: z.string(),
        }),
      },
    ),
    settingsSurveyDataCollections: defineRoute(
      '/settings/cxm/[surveyId]/data-collections',
      {
        params: z.object({
          surveyId: z.string(),
        }),
      },
    ),
    settingsSurveyDataCollectionDetails: defineRoute(
      '/settings/cxm/[surveyId]/data-collections/[dataCollectionId]',
      {
        params: z.object({
          dataCollectionId: z.string(),
          surveyId: z.string(),
        }),
      },
    ),
    settingsSurveyDrawAWinner: defineRoute(
      '/settings/cxm/[surveyId]/draw-a-winner',
      {
        params: z.object({
          surveyId: z.string(),
        }),
      },
    ),
    settingsSurveyEmailInvitation: defineRoute(
      '/settings/cxm/[surveyId]/email-invitation',
      {
        params: z.object({
          surveyId: z.string(),
        }),
      },
    ),
    settingsSurveyGolfGenius: defineRoute(
      '/settings/cxm/[surveyId]/golf-genius',
      {
        params: z.object({
          surveyId: z.string(),
        }),
      },
    ),
    settingsSurveyIntegrations: defineRoute(
      '/settings/cxm/[surveyId]/integrations',
      {
        params: z.object({
          surveyId: z.string(),
        }),
      },
    ),
    settingsSurveyIntegrationProvider: defineRoute(
      '/settings/cxm/[surveyId]/integrations/[providerId]',
      {
        params: z.object({
          providerId: z.nativeEnum(
            CoreEntitiesIntegrationsSurveyIntegrationProviderEnum,
          ),
          surveyId: z.string(),
        }),
      },
    ),
    settingsSurveyNotifications: defineRoute(
      '/settings/cxm/[surveyId]/notifications',
      {
        params: z.object({
          surveyId: z.string(),
        }),
      },
    ),
    settingsSurveyQuestionnaireDesign: defineRoute(
      '/settings/cxm/[surveyId]/questionnaire-design',
      {
        params: z.object({
          surveyId: z.string(),
        }),
      },
    ),
    settingsSurveyShareableLink: defineRoute(
      '/settings/cxm/[surveyId]/shareable-link',
      {
        params: z.object({
          surveyId: z.string(),
        }),
      },
    ),
    //
    signOut: defineRoute('/sign-out'),
  }))
