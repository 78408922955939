// @ts-nocheck
export const SystemDayOfWeek = {
  Sunday: 'Sunday',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
} as const
export type SystemDayOfWeek =
  (typeof SystemDayOfWeek)[keyof typeof SystemDayOfWeek]
