import clsx from 'clsx'
import styles from './table.module.css'

type Theme = 'light' | 'dark'

interface TableHeadProps {
  children: React.ReactNode
}

export function TableHead({ children, ...props }: TableHeadProps) {
  return (
    <thead className={styles.tableHead} {...props}>
      {children}
    </thead>
  )
}

interface TableBodyProps {
  children: React.ReactNode
}

export function TableBody({ children, ...props }: TableBodyProps) {
  return (
    <tbody className={styles.tableBody} {...props}>
      {children}
    </tbody>
  )
}

interface TableRowProps {
  children: React.ReactNode
  hasRowHover?: boolean
  isActive?: boolean
  onRowClick?: () => void
}

export function TableRow({
  children,
  hasRowHover,
  isActive,
  onRowClick,
  ...props
}: TableRowProps) {
  return (
    <tr
      className={clsx(
        styles.row,
        isActive && styles.rowActive,
        hasRowHover && styles.rowHover,
      )}
      onClick={onRowClick}
      {...props}
    >
      {children}
    </tr>
  )
}

interface TableCellProps {
  align?: 'left' | 'center' | 'right'
  children?: React.ReactNode
  heading?: boolean
  isSmall?: boolean
  isWide?: boolean
  minWidth?: number
  noWrap?: boolean
  style?: React.CSSProperties
}

export function TableCell({
  align = 'left',
  children,
  heading,
  isSmall,
  isWide,
  minWidth,
  noWrap,
  style,
  ...props
}: TableCellProps) {
  const Tag = heading ? 'th' : 'td'
  return (
    <Tag
      className={clsx(
        styles.cell,
        isSmall && styles.cellSmall,
        heading && styles.cellWithBorder,
        align === 'center' && styles.cellCenterAlign,
        align === 'right' && styles.cellRightAlign,
        heading && styles.cellHeading,
        !heading && styles.cellBody,
        isWide && styles.cellWide,
        noWrap && styles.cellNoWrap,
      )}
      style={{ minWidth: `${minWidth || 50}px`, ...style }}
      {...props}
    >
      {children}
    </Tag>
  )
}
