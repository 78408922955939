// @ts-nocheck
export const CoreEnumsGenericOperatorType = {
  Sum: 'Sum',
  Avg: 'Avg',
  Median: 'Median',
  Fraction: 'Fraction',
  Percentage: 'Percentage',
  Product: 'Product',
  Multiply: 'Multiply',
  Difference: 'Difference',
} as const
export type CoreEnumsGenericOperatorType =
  (typeof CoreEnumsGenericOperatorType)[keyof typeof CoreEnumsGenericOperatorType]
