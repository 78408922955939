import type {
  CommonApiDtOsSurveyQuestionCustomNamingDto,
  CommonApiDtOsSurveyQuestionsContactInfoQuestionDto,
  CommonApiDtOsSurveyQuestionsDateQuestionDto,
  CommonApiDtOsSurveyQuestionsIntervalQuestionDto,
  CommonApiDtOsSurveyQuestionsMultipleQuestionDto,
  CommonApiDtOsSurveyQuestionsNumericQuestionDto,
  CommonApiDtOsSurveyQuestionsQuestionDto,
  CommonApiDtOsSurveyQuestionsSingleQuestionDto,
  CommonApiDtOsSurveyQuestionsTextQuestionDto,
  CoreDtOsCurrencyLanguageDto,
  CoreDtOsTranslationDto,
  CoreDtOsTranslationLanguageDto,
} from '@/api/models'
import { routing } from '@/i18n/routing'
import { isNilOrEmpty } from '@/utils/isNilOrEmpty'
import { getCookie } from 'cookies-next/client'

function getLocaleFromCookie() {
  return getCookie('NEXT_LOCALE')
}

export function getCurrencyTranslation(
  currencies: CoreDtOsCurrencyLanguageDto[],
  languageCode?: string | undefined,
) {
  if (isNilOrEmpty(currencies)) {
    return ''
  }

  const code = languageCode || getLocaleFromCookie() || routing.defaultLocale

  let currency = currencies.find((currency) => currency.isDefault)

  if (currency == null) {
    currency = currencies
      .filter((currency) => currency.isActive && currency.deletedAt == null)
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .find((currency) => currency.currencyName.currencyCode === code)
  }

  return currency?.currencyName.value ?? ''
}

export function getBITranslation(
  languages: CoreDtOsTranslationLanguageDto[] | undefined,
  languageCode?: string | undefined,
) {
  if (isNilOrEmpty(languages)) {
    return ''
  }

  const code = languageCode || getLocaleFromCookie() || routing.defaultLocale

  let language = languages.find((language) => language.isDefault)

  if (language == null) {
    language = languages
      .filter((language) => language.isActive && !language.deletedAt == null)
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .find((language) => language.languageName.languageCode === code)
  }

  return language?.languageName.value ?? ''
}

export const getTranslation = (
  translations: CoreDtOsTranslationDto[] | undefined,
  languageCode?: string | undefined,
  languageCodeFallback?: string | undefined,
): string => {
  if (isNilOrEmpty(translations)) {
    return ''
  }

  const code = languageCode || getLocaleFromCookie() || routing.defaultLocale

  let translation = translations.find(
    (translation: CoreDtOsTranslationDto) => translation.languageCode === code,
  )

  // Use fallback language if translation is not available.
  if (!translation && languageCodeFallback != null) {
    translation = translations.find(
      (translation: CoreDtOsTranslationDto) =>
        translation.languageCode === languageCodeFallback,
    )
  }

  // Fallback to default language.
  if (!translation) {
    translation = translations.find(
      (translation: CoreDtOsTranslationDto) =>
        translation.languageCode === routing.defaultLocale,
    )
  }

  // Grab the first translation if none of the above is available.
  if (!translation) {
    translation = translations[0]
  }

  return translation?.value ?? ''
}

export const getCustomNamingKey = (
  customNamings: CommonApiDtOsSurveyQuestionCustomNamingDto[],
): string => {
  let key = ''

  customNamings.forEach((customNaming) => {
    key = customNaming.key
  })

  return key
}

export const getCustomNamingDefault = (
  customNamings: CommonApiDtOsSurveyQuestionCustomNamingDto[],
  languageCode: string | undefined,
): string => {
  let defaultName = ''

  customNamings.forEach((customNaming) => {
    const customNameTranslation = customNaming.defaultText.find(
      (translation: CoreDtOsTranslationDto) =>
        translation.languageCode === languageCode,
    )

    if (customNameTranslation) {
      defaultName = customNameTranslation.value
    }
  })

  return defaultName
}

export const getCustomNamingText = (
  customNamings: (
    | CommonApiDtOsSurveyQuestionCustomNamingDto
    | { key: string; text: CoreDtOsTranslationDto[] }
  )[],
  languageCode: string | undefined,
): string => {
  let customName = ''
  if (languageCode == null) {
    return customName
  }

  customNamings.forEach((customNaming) => {
    // Try custom text.
    let customNameTranslation = customNaming.text.find(
      (translation: CoreDtOsTranslationDto) =>
        translation.languageCode === languageCode,
    )

    // Fallback to default text.
    if (!customNameTranslation && 'defaultText' in customNaming) {
      customNameTranslation = customNaming.defaultText.find(
        (translation: CoreDtOsTranslationDto) =>
          translation.languageCode === languageCode,
      )
    }

    if (customNameTranslation) {
      customName = customNameTranslation.value
    }
  })

  return customName
}

// Get question translation with possible custom naming.
export const getQuestionTranslation = (
  question:
    | (
        | CommonApiDtOsSurveyQuestionsQuestionDto
        | CommonApiDtOsSurveyQuestionsContactInfoQuestionDto
        | CommonApiDtOsSurveyQuestionsDateQuestionDto
        | CommonApiDtOsSurveyQuestionsIntervalQuestionDto
        | CommonApiDtOsSurveyQuestionsMultipleQuestionDto
        | CommonApiDtOsSurveyQuestionsNumericQuestionDto
        | CommonApiDtOsSurveyQuestionsSingleQuestionDto
        | CommonApiDtOsSurveyQuestionsTextQuestionDto
      )
    | undefined,
  languageCode: string | undefined,
): string => {
  if (question == null || languageCode == null) {
    return ''
  }
  let translation = getTranslation(question.questionText, languageCode)

  if (question.hasCustomNamings) {
    const key = getCustomNamingKey(question.customNamings)
    const text = getCustomNamingText(question.customNamings, languageCode)
    const regEx = new RegExp(key, 'g')
    translation = translation.replace(regEx, `<strong>${text}</strong>`)
  }

  return translation
}
