import { useGetV1SurveysOverview } from '@/api/client-hooks'
import type { CoreDtOsAccountAccountSurveyOverviewDto } from '@/api/models'
import { useEnabled } from '@/hooks/useEnabled'

const select = (data: CoreDtOsAccountAccountSurveyOverviewDto) => {
  const surveys = (data?.surveys ?? []).sort(
    (a, b) => a.sortOrder - b.sortOrder,
  )
  return { ...data, surveys }
}

export function useSurveysOverview() {
  const enabled = useEnabled()
  return useGetV1SurveysOverview(undefined, { query: { enabled, select } })
}
