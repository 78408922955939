import { useGetV1AccountsAccountid } from '@/api/client-hooks'
import { useEnabled } from '@/hooks/useEnabled'
import { getAccountId } from '@/lib/account'

export default function useAccount() {
  const accountId = getAccountId()
  const enabled = useEnabled()
  return useGetV1AccountsAccountid(accountId, {
    query: { enabled },
  })
}
