'use client'

import { useGetV1TournamentsDataCollections } from '@/api/client-hooks'
import type { CoreDtOsTournamentTournamentDataCollectionDto } from '@/api/models'
import {
  CoreEntitiesIntegrationsAccountIntegrationStatus,
  CoreEntitiesIntegrationsSurveyIntegrationProviderEnum,
  CoreEntitiesSurveyDataCollectionStatusEnum,
} from '@/api/models'
import { Badge } from '@/components/common/badge'
import { PageHeaderLeft } from '@/components/common/page-header-left'
import { PageHeaderLinks } from '@/components/common/page-header-links'
import { RichTable } from '@/components/common/rich-table'
import { Section } from '@/components/common/section'
import { notifyError, notifySuccess } from '@/components/common/toast'
import { NoData } from '@/components/dashboard/no-data'
import { Button } from '@/components/ui/button'
import { ContextMenu, ContextMenuItem } from '@/components/ui/context-menu'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogModal,
  DialogTrigger,
} from '@/components/ui/dialog'
import { LinkButton } from '@/components/ui/link-button'
import { Radio, RadioBody, RadioGroup } from '@/components/ui/radio-group'
import { DATA_COLLECTION_CANCELLED } from '@/constants/events'
import { routes } from '@/constants/routes'
import { useActiveAccount } from '@/hooks/accounts/useActiveAccount'
import { useDateFormatter } from '@/hooks/i18n/useDateFormatter'
import { usePercentFormatter } from '@/hooks/i18n/usePercentFormatter'
import useIntegration from '@/hooks/integrations/useIntegration'
import { useActiveSurveyOverview } from '@/hooks/surveys/useActiveSurveyOverview'
import { useDeleteDataCollection } from '@/hooks/tournament/useTournament'
import { useContextStore } from '@/providers/context-store-provider'
import { nl2br } from '@/utils'
import { getTimestampInSeconds } from '@/utils/getTimestampInSeconds'
import { isNilOrEmpty } from '@/utils/isNilOrEmpty'
import { isNilOrNaN } from '@/utils/isNilOrNaN'
import { logError } from '@/utils/logError'
import { Flex } from '@radix-ui/themes'
import { createColumnHelper } from '@tanstack/react-table'
import { parseISO } from 'date-fns'
import { HTTPError } from 'ky'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/navigation'
import { useMemo, useState } from 'react'
import { useIntercom } from 'react-use-intercom'

interface EditDataCollectionButtonProps {
  accountSurveyId: string
  children: React.ReactNode
  accountId: string
}

interface DeleteDataCollectionButtonProps {
  children: React.ReactNode
  dataCollectionId: string
  endDate: string
  respondentCount: number
  startDate: string
}

function EditDataCollectionButton({
  accountId,
  accountSurveyId,
  children,
}: EditDataCollectionButtonProps) {
  const router = useRouter()
  const { setAccountId } = useContextStore((state) => state)

  const editDataCollectionHandler = () => {
    setAccountId(accountId)
    requestAnimationFrame(() =>
      router.push(
        routes.settingsSurveyQuestionnaireDesign({
          surveyId: accountSurveyId,
        }),
      ),
    )
  }

  return (
    <ContextMenuItem onAction={editDataCollectionHandler}>
      {children}
    </ContextMenuItem>
  )
}

function DeleteDataCollectionButton({
  children,
  dataCollectionId,
  endDate,
  respondentCount,
  startDate,
}: DeleteDataCollectionButtonProps) {
  const tournamentOverviewT = useTranslations('tournament-overview')
  const { mutateAsync: deleteDataCollection } = useDeleteDataCollection()
  const { trackEvent } = useIntercom()
  const { surveyName } = useActiveSurveyOverview()
  const { activeAccount } = useActiveAccount()
  const accountName = activeAccount?.name ?? ''

  const deleteDataCollectionHandler = async ({
    dataCollectionId,
    endDate,
    respondentCount,
    startDate,
  }: {
    dataCollectionId: string
    endDate: string
    respondentCount: number
    startDate: string
  }) => {
    if (window.confirm(tournamentOverviewT('deleteConfirm'))) {
      try {
        await deleteDataCollection({
          dataCollectionId,
        })
        notifySuccess(tournamentOverviewT('deleteDataCollection.saveSuccess'))
        trackEvent(DATA_COLLECTION_CANCELLED, {
          account: accountName,
          end_date: getTimestampInSeconds(parseISO(endDate)),
          respondents: respondentCount,
          start_date: getTimestampInSeconds(parseISO(startDate)),
          survey: surveyName,
        })
      } catch (error) {
        if (error instanceof HTTPError) {
          const status = error.response.status
          const errorJson: { error: string } = await error.response.json()

          if (
            status === 400 &&
            errorJson.error === 'data_collection_already_ongoing_can_not_delete'
          ) {
            notifyError(
              tournamentOverviewT('deleteDataCollection.saveErrorOngoing'),
            )
          } else {
            notifyError(tournamentOverviewT('deleteDataCollection.saveError'))
            logError('Delete data collection', error, {
              dataCollectionId,
            })
          }
        }
      }
    }
  }

  return (
    <ContextMenuItem
      onAction={() =>
        deleteDataCollectionHandler({
          dataCollectionId,
          endDate,
          respondentCount,
          startDate,
        })
      }
    >
      {children}
    </ContextMenuItem>
  )
}

export function TournamentOverview() {
  const router = useRouter()
  const tournamentOverviewT = useTranslations('tournament-overview')
  const { data, isFetched } = useGetV1TournamentsDataCollections()
  const dataCollections = data?.dataCollections ?? []
  const [isContactModalActive, setContactModalActive] = useState(false)
  const dateFormatter = useDateFormatter('dayMonthYear')
  const percentFormatter = usePercentFormatter()
  const columnHelper =
    createColumnHelper<CoreDtOsTournamentTournamentDataCollectionDto>()
  const sortBy = [{ desc: true, id: 'startDate' }]
  const integrationQuery = useIntegration(
    CoreEntitiesIntegrationsSurveyIntegrationProviderEnum.GolfGenius,
  )
  const isGolfGeniusIntegrationRunning =
    integrationQuery.data?.status ===
    CoreEntitiesIntegrationsAccountIntegrationStatus.Running

  const dataCollectionTypes = [
    {
      id: 'golf-genius',
      name: tournamentOverviewT(
        'dataCollectionTypeModal.type.golfGenius.title',
      ),
      body: tournamentOverviewT('dataCollectionTypeModal.type.golfGenius.text'),
    },
    {
      id: 'manual',
      name: tournamentOverviewT('dataCollectionTypeModal.type.manual.title'),
      body: tournamentOverviewT('dataCollectionTypeModal.type.manual.text'),
    },
  ]

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: () => tournamentOverviewT('header.name'),
        enableColumnFilter: false,
      }),

      columnHelper.accessor('dataCollectionStatus', {
        header: () => tournamentOverviewT('header.status'),
        enableColumnFilter: false,
        cell: (props) => {
          const status = props.getValue()
          let theme: 'blue' | 'green' | 'grey' = 'grey'

          switch (status) {
            case CoreEntitiesSurveyDataCollectionStatusEnum.Ongoing:
              theme = 'blue'
              break

            case CoreEntitiesSurveyDataCollectionStatusEnum.Completed:
              theme = 'green'
              break
          }

          return <Badge theme={theme}>{status}</Badge>
        },
      }),

      columnHelper.accessor('respondentAnswerCount', {
        header: () => tournamentOverviewT('header.answers'),
        enableColumnFilter: false,
        cell: (props) => {
          const respondentAnswerCount = props.getValue()
          const { respondentCount } = props.row.original
          const respondentAnswerPercent =
            (1 / respondentCount) * respondentAnswerCount
          return (
            <>
              {respondentAnswerCount}/{respondentCount}
              <span className="whitespace-nowrap">
                {' '}
                (
                {percentFormatter.format(
                  isNilOrNaN(respondentAnswerPercent)
                    ? 0
                    : respondentAnswerPercent,
                )}
                )
              </span>
            </>
          )
        },
      }),

      columnHelper.accessor(
        (row) =>
          row.startDate != null ? parseISO(row.startDate).toISOString() : null,
        {
          id: 'startDate',
          header: () => tournamentOverviewT('header.startDate'),
          enableColumnFilter: false,
          meta: {
            type: 'date',
          },
          cell: (props) => {
            const value = props.getValue()
            if (value == null) {
              return null
            }

            return (
              <time dateTime={new Date(value).toISOString()}>
                {dateFormatter.format(parseISO(value))}
              </time>
            )
          },
        },
      ),

      columnHelper.accessor(
        (row) =>
          row.reminderDates.length > 0
            ? parseISO(row.reminderDates[0]).toISOString()
            : null,
        {
          id: 'reminderDates',
          header: () => tournamentOverviewT('header.reminder'),
          // sortingFn: 'datetime',
          enableColumnFilter: false,
          meta: {
            type: 'date',
          },
          cell: ({ row }) => {
            const { reminderDates } = row.original
            if (isNilOrEmpty(reminderDates)) {
              return null
            }

            return reminderDates.map((reminderDate, index) => (
              <div key={reminderDate}>
                <time dateTime={reminderDate}>
                  {dateFormatter.format(parseISO(reminderDate))}
                </time>
              </div>
            ))
          },
        },
      ),

      columnHelper.accessor(
        (row) =>
          row.endDate != null ? parseISO(row.endDate).toISOString() : null,
        {
          id: 'endDate',
          header: () => tournamentOverviewT('header.endDate'),
          enableColumnFilter: false,
          meta: {
            type: 'date',
          },
          cell: (props) => {
            const value = props.getValue()
            if (value == null) {
              return null
            }

            return (
              <time dateTime={new Date(value).toISOString()}>
                {dateFormatter.format(parseISO(value))}
              </time>
            )
          },
        },
      ),
      columnHelper.display({
        id: 'actions',
        cell: ({ row }) => {
          const {
            accountId,
            accountSurveyId,
            dataCollectionId,
            dataCollectionStatus,
            endDate,
            respondentCount,
            startDate,
          } = row.original
          const isDraft =
            dataCollectionStatus ===
            CoreEntitiesSurveyDataCollectionStatusEnum.Draft
          const isOngoing =
            dataCollectionStatus ===
            CoreEntitiesSurveyDataCollectionStatusEnum.Ongoing
          const isScheduled =
            dataCollectionStatus ===
            CoreEntitiesSurveyDataCollectionStatusEnum.Scheduled

          return (
            <ContextMenu>
              {(isOngoing || isScheduled) && (
                <EditDataCollectionButton
                  accountSurveyId={accountSurveyId}
                  accountId={accountId}
                >
                  {tournamentOverviewT('editDataCollectionButton')}
                </EditDataCollectionButton>
              )}

              {(isDraft || isScheduled) && (
                <DeleteDataCollectionButton
                  dataCollectionId={dataCollectionId}
                  endDate={endDate}
                  respondentCount={respondentCount}
                  startDate={startDate}
                >
                  {tournamentOverviewT('deleteDataCollectionButton')}
                </DeleteDataCollectionButton>
              )}
            </ContextMenu>
          )
        },
      }),
    ]
  }, [columnHelper, dateFormatter, percentFormatter, tournamentOverviewT])

  const handleDataCollectionType = (id: 'golf-genius' | 'manual') => {
    if (id === 'golf-genius') {
      if (isGolfGeniusIntegrationRunning) {
        router.push(routes.createTournament())
      } else {
        notifyError(tournamentOverviewT('inactiveIntegration'))
      }
    } else if (id === 'manual') {
      router.push(routes.settingsClubs({ search: { create: true } }))
    }
  }

  return (
    <>
      <PageHeaderLeft
        title={tournamentOverviewT('pageTitle')}
        text={tournamentOverviewT('pageDescription')}
        links={
          <PageHeaderLinks
            articleLink={tournamentOverviewT('pageArticleLink')}
            // videoLink={tournamentOverviewT('pageVideoLink')}
          />
        }
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="58"
            height="62"
            fill="none"
            aria-hidden="true"
          >
            <path
              fill="#283444"
              d="M12.699 1.216c0 .804-.046 1.59-.088 2.374.558 0 1.112-.034 1.666 0l5.319.133c2.311.054 4.581-.1 6.905-.046 1.25.034 2.579.15 3.84.071 1.446-.087 2.916.088 4.337-.03a16.883 16.883 0 0 1 1.836 0c.73.026 1.412-.062 2.133-.062.616 0 1.25.063 1.845.059.596-.004 1.154-.092 1.745-.071.833.03 1.712-.054 2.57-.1-.042-.22-.08-.442-.117-.662.007-.06.019-.12.034-.18.025-.079.095-.12.116-.212.021-.091-.029-.191 0-.279.03-.087.113-.108.138-.196.025-.087-.034-.225 0-.329.075-.266.416-.795.195-1.045-.095-.104-.358-.113-.487-.146-.129-.033-.108-.079-.25-.116a3.332 3.332 0 0 0-.354 0A2.953 2.953 0 0 0 43 .404C32.474-.071 22.75-.11 11.845.195c.808 1.162.833 1.092.854 1.02ZM44.578 4.098l-.08.258.071.063"
            />
            <path
              fill="#283444"
              d="M11.87.962a.281.281 0 0 0-.121-.18c.018.24.063.478.133.709.033.141.083.17.088.32a1.7 1.7 0 0 0 .079.346c.091.45.204.854.287 1.308.02.116.05.237.075.35.028.11.064.22.108.325.142.204.454.141.68.145.315 0 .63-.027.94-.079.156-.048.314-.082.475-.104.15.019.3.019.45 0v-.087"
            />
            <path
              fill="#283444"
              d="M55.873 5.252c-3.257.529-6.864 1.3-10.533 2.111-.133-.833-.28-1.632-.417-2.449-.258 0-.52.03-.782.03-1.283 0-2.57.162-3.853.12-1.433-.05-2.84.038-4.278 0-1.25-.041-2.528.133-3.794.121-.733 0-1.454.058-2.187.058-.733 0-1.374-.07-2.082-.033-1.591.08-3.174-.038-4.76.046-1.305.07-2.63-.046-3.941 0-2.245.091-4.473.025-6.718-.034-.025.334-.042.675-.067 1.004-3.424-.862-6.776-1.666-9.83-2.286a20.892 20.892 0 0 0-.537 19.267c2.7 5.485 7.914 11.629 14.02 13.745 2.553 3.778 6.351 7.73 11.92 11.995v5.623c.296.762-.72 1.454-1.75 1.962a32.579 32.579 0 0 1-10.966 2.957c-.045.32-.091.637-.112.958-.204.47-.417.937-.637 1.37a323.983 323.983 0 0 1 34.257-.125c-.47-.924-.941-1.85-1.483-2.711-2.761-.417-5.635-.525-8.363-.996-2.728-.47-5.415-1.424-6.472-2.915-.538-.762-.58-1.595-.617-2.408l-.175-4.115a25.864 25.864 0 0 0 11.217-11.033c.05-.096.091-.192.141-.287a23.436 23.436 0 0 0 12.333-11.05 24.435 24.435 0 0 0 .466-20.925ZM5.26 23.349c-2.633-4.82-2.562-9.934-.363-14.932 2.5.704 4.853 1.316 7.16 2.045-.77 7.102-1.666 13.682.995 20.663a20.234 20.234 0 0 1-7.792-7.776Zm29.113-2.412a5.83 5.83 0 0 1-3.856 3.04 5.299 5.299 0 0 1-2.72-.2 5.644 5.644 0 0 1-1.762-.716 6.99 6.99 0 0 1-3.07-5.081 3.64 3.64 0 0 1 .388-2.499c.146-.218.318-.418.512-.596.073-.218.165-.43.275-.633.62-1.095 1.845-1.84 3.145-2.216a6.414 6.414 0 0 1 4.544.259 4.915 4.915 0 0 1 2.082 1.995c1.504 1.882 1.579 4.552.462 6.647Zm17.91 5.232a21.05 21.05 0 0 1-7.739 7.792c1.62-4.498 2.187-9.392 2.083-14.252a73.47 73.47 0 0 0-.641-8.093 80.271 80.271 0 0 1 7.872-2.428 20.94 20.94 0 0 1-1.596 16.98h.021Z"
            />
            <path
              fill="#283444"
              d="M29.775 13.174a1.91 1.91 0 0 1-.308 0c-.08-.021-.104-.088-.188-.105a1.214 1.214 0 0 0-.608.084c-.1.02-.216-.034-.316 0-.067.024-.13.055-.192.091-.226.07-.448.157-.662.259-.67.349-1.26.83-1.737 1.416a5.623 5.623 0 0 0-.683 1.053c-.1.213-.184.433-.25.659-.025.116 0 .229-.025.35a1.15 1.15 0 0 0-.087.337c0 .212.095.379.087.595-.023.229-.03.458-.02.688.1.433.254.853.457 1.25.184.4.419.776.7 1.115.141.18.308.34.496.471.096.07.204.108.3.18a2.5 2.5 0 0 0 .416.328 5.031 5.031 0 0 0 3.749.263 2.13 2.13 0 0 0 .625-.33 8.32 8.32 0 0 0 .716-.487c.333-.357.583-.784.733-1.25.093-.233.165-.474.217-.72.025-.125.095-.237.12-.362a3.377 3.377 0 0 1 .017-.5 1.867 1.867 0 0 0 0-.87c-.042-.325-.067-.646-.133-.967a3.575 3.575 0 0 0-.23-.958c-.145-.279-.295-.554-.416-.833a4.238 4.238 0 0 0-.529-.883 2.915 2.915 0 0 0-.604-.45 4.58 4.58 0 0 0-1.2-.508 2.752 2.752 0 0 0-.653-.108 1.217 1.217 0 0 1-.275-.08c-.146-.028-.22.063-.367.055v-.083"
            />
          </svg>
        }
      >
        {data != null && (
          <Flex direction="column" gap="4" style={{ textAlign: 'right' }}>
            {data.trialsLeft != null && (
              <>
                <div>
                  {tournamentOverviewT('trailDataCollectionsLeft', {
                    count: Math.max(0, data.trialsLeft),
                  })}
                </div>
                {data.trialsLeft <= 0 && (
                  <DialogTrigger>
                    <Button>{tournamentOverviewT('buyProduct')}</Button>

                    <DialogModal isDismissable>
                      <Dialog size="sm">
                        {({ close }) => (
                          <>
                            <DialogHeader>
                              {tournamentOverviewT('contactModal.title')}
                            </DialogHeader>

                            <DialogContent>
                              <p className="text-3">
                                {tournamentOverviewT('contactModal.text')}
                              </p>
                            </DialogContent>

                            <DialogFooter>
                              <Flex gap="3" justify="end">
                                <LinkButton
                                  href="mailto:sales@players1st.sport"
                                  onPress={() => setContactModalActive(false)}
                                >
                                  {tournamentOverviewT(
                                    'contactModal.buttonLabel',
                                  )}
                                </LinkButton>
                              </Flex>
                            </DialogFooter>
                          </>
                        )}
                      </Dialog>
                    </DialogModal>
                  </DialogTrigger>
                )}
              </>
            )}

            {(data.trialsLeft == null || data.trialsLeft > 0) && (
              <DialogTrigger>
                <Button>{tournamentOverviewT('createDataCollection')}</Button>

                <DialogModal isDismissable>
                  <Dialog size="sm">
                    {({ close }) => (
                      <>
                        <DialogHeader>
                          {tournamentOverviewT('dataCollectionTypeModal.title')}
                        </DialogHeader>

                        <DialogContent>
                          <RadioGroup
                            label={tournamentOverviewT(
                              'dataCollectionTypeModal.subtitle',
                            )}
                            onChange={(id) => {
                              handleDataCollectionType(
                                id as 'golf-genius' | 'manual',
                              )
                              close()
                            }}
                          >
                            {dataCollectionTypes.map((item) => {
                              return (
                                <Radio key={item.id} value={item.id}>
                                  <RadioBody title={item.name}>
                                    {item.body}
                                  </RadioBody>
                                </Radio>
                              )
                            })}
                          </RadioGroup>
                        </DialogContent>
                      </>
                    )}
                  </Dialog>
                </DialogModal>
              </DialogTrigger>
            )}
          </Flex>
        )}
      </PageHeaderLeft>

      <Section>
        <RichTable<CoreDtOsTournamentTournamentDataCollectionDto>
          columns={columns}
          data={dataCollections}
          noData={
            isFetched && dataCollections.length === 0 ? (
              <NoData title={tournamentOverviewT('noData.title')}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: nl2br(tournamentOverviewT('noData.text')),
                  }}
                />
              </NoData>
            ) : null
          }
          sortBy={sortBy}
        />
      </Section>
    </>
  )
}
