import { Flex } from '@radix-ui/themes'
import { clsx } from 'clsx'
import type { VariantProps } from 'cva'
import { cva } from 'cva'
import { VisuallyHidden } from 'react-aria'
import type { ButtonProps } from 'react-aria-components'
import { Button } from 'react-aria-components'
import styles from './button.module.css'
import { Spinner } from './spinner'

export const buttonVariants = cva({
  base: styles.button,
  variants: {
    variant: {
      primary: styles.variantPrimary,
      secondary: styles.variantSecondary,
      outline: styles.variantOutline,
      ghost: styles.variantGhost,
      destructive: styles.variantDestructive,
      unstyled: styles.variantUnstyled,
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
})

export interface Props
  extends ButtonProps,
    VariantProps<typeof buttonVariants> {
  children?: React.ReactNode
  isLoading?: boolean
}

function _Button({
  children,
  className,
  isDisabled,
  isLoading,
  variant,
  ...props
}: Props) {
  return (
    <Button
      {...props}
      className={clsx(
        isLoading && styles.isLoading,
        buttonVariants({ variant }),
        className,
      )}
      isDisabled={isDisabled || isLoading}
    >
      {isLoading ? (
        <>
          {/**
           * We need a wrapper to set `visibility: hidden` to hide the button content whilst we show the `Spinner`.
           * The button is a flex container with a `gap`, so we use `display: contents` to ensure the correct flex layout.
           *
           * However, `display: contents` removes the content from the accessibility tree in some browsers,
           * so we force remove it with `aria-hidden` and re-add it in the tree with `VisuallyHidden`
           */}
          <span
            style={{ display: 'contents', visibility: 'hidden' }}
            aria-hidden="true"
          >
            {children}
          </span>
          <VisuallyHidden>{children}</VisuallyHidden>

          <Flex
            asChild
            align="center"
            justify="center"
            position="absolute"
            inset="0"
          >
            <span>
              <Spinner />
            </span>
          </Flex>
        </>
      ) : (
        children
      )}
    </Button>
  )
}

export { _Button as Button }
