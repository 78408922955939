import { IconButton } from '@/components/ui/icon-button'
import { ArrowLeft, ArrowRight } from '@phosphor-icons/react/dist/ssr'
import { useTranslations } from 'next-intl'
import styles from './table-pagination.module.css'

interface Props {
  canNextPage: boolean
  canPreviousPage: boolean
  pageIndex: number
  pageSize: number
  totalItems: number
  previousPage: () => void
  nextPage: () => void
}

export function TablePagination({
  canNextPage,
  canPreviousPage,
  pageIndex,
  pageSize,
  totalItems,
  previousPage,
  nextPage,
}: Props) {
  const t = useTranslations('common')

  if (!canPreviousPage && !canNextPage) {
    return null
  }

  return (
    <div className={styles.pagination}>
      <IconButton
        aria-label={t('previous')}
        className={styles.button}
        isDisabled={!canPreviousPage}
        size="sm"
        variant="ghost"
        onPress={previousPage}
      >
        <ArrowLeft weight="bold" />
      </IconButton>
      <div>
        {t('pagination', {
          count: totalItems,
          from: pageIndex * pageSize + 1,
          to: Math.min(pageIndex * pageSize + pageSize, totalItems),
        })}
      </div>
      <IconButton
        aria-label={t('next')}
        className={styles.button}
        isDisabled={!canNextPage}
        size="sm"
        variant="ghost"
        onPress={nextPage}
      >
        <ArrowRight weight="bold" />
      </IconButton>
    </div>
  )
}
