// @ts-nocheck
export const CoreEntitiesSurveyNotificationFlagEnum = {
  None: 'None',
  SurveyPromotors: 'SurveyPromotors',
  SurveyPassive: 'SurveyPassive',
  SurveyDetractors: 'SurveyDetractors',
  SurveyWithoutComments: 'SurveyWithoutComments',
  SurveyIncludeAnonymous: 'SurveyIncludeAnonymous',
} as const
export type CoreEntitiesSurveyNotificationFlagEnum =
  (typeof CoreEntitiesSurveyNotificationFlagEnum)[keyof typeof CoreEntitiesSurveyNotificationFlagEnum]
