import { CoreEntitiesSurveySurveyType } from '@/api/models'
import { useSurveysOverview } from '@/hooks/surveys/useSurveysOverview'
import { useSurveyTranslation } from '@/hooks/useSurveyTranslation'
import { getDefaultSurveyLanguage } from '@/utils/getDefaultSurveyLanguage'
import { useParams } from 'next/navigation'

export function useActiveSurveyOverview() {
  const params = useParams()
  const surveyId = params?.surveyId
  const { data: surveysOverviewData } = useSurveysOverview()
  const surveys = surveysOverviewData?.surveys ?? []

  const surveyOverview = surveys.find(
    (item) => item.accountSurveyId === surveyId,
  )
  const isEntrySurvey =
    surveyOverview?.surveyType === CoreEntitiesSurveySurveyType.Entry
  const isExitSurvey =
    surveyOverview?.surveyType === CoreEntitiesSurveySurveyType.Exit
  const isGuestSurvey =
    surveyOverview?.surveyType === CoreEntitiesSurveySurveyType.Guest
  const isMemberSurvey =
    surveyOverview?.surveyType === CoreEntitiesSurveySurveyType.Member
  const isNPSSurvey =
    surveyOverview?.surveyType === CoreEntitiesSurveySurveyType.NetPromoterScore
  const surveyLanguage = getDefaultSurveyLanguage(surveyOverview)
  const surveyTranslator = useSurveyTranslation()
  const { surveyName, surveyDescription } = surveyTranslator(surveyOverview)

  return {
    isEntrySurvey,
    isExitSurvey,
    isGuestSurvey,
    isMemberSurvey,
    isNPSSurvey,
    surveyDescription,
    surveyLanguage,
    surveyName,
    surveyType: surveyOverview?.surveyType,
    surveyOverview,
  }
}
