// @ts-nocheck
export const CoreDtOsBiRoundsPerMemberDataRangeEnum = {
  ZeroRounds: 'ZeroRounds',
  OneToTenRounds: 'OneToTenRounds',
  ElevenToTwentyRounds: 'ElevenToTwentyRounds',
  TwentyOneToThirtyRounds: 'TwentyOneToThirtyRounds',
  ThirtyPlusRounds: 'ThirtyPlusRounds',
} as const
export type CoreDtOsBiRoundsPerMemberDataRangeEnum =
  (typeof CoreDtOsBiRoundsPerMemberDataRangeEnum)[keyof typeof CoreDtOsBiRoundsPerMemberDataRangeEnum]
