// @ts-nocheck
export const CoreEntitiesIntegrationsAccountIntegrationStatus = {
  AwaitingRequest: 'AwaitingRequest',
  AwaitingForm: 'AwaitingForm',
  AwaitingConnectionTest: 'AwaitingConnectionTest',
  AwaitingConnectionTestRetry: 'AwaitingConnectionTestRetry',
  ConnectionTestFailed: 'ConnectionTestFailed',
  Running: 'Running',
} as const
export type CoreEntitiesIntegrationsAccountIntegrationStatus =
  (typeof CoreEntitiesIntegrationsAccountIntegrationStatus)[keyof typeof CoreEntitiesIntegrationsAccountIntegrationStatus]
