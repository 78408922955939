import { useContextStore } from '@/providers/context-store-provider'
import { isNilOrEmpty } from '@/utils/isNilOrEmpty'

export function useEnabled() {
  const { accountId } = useContextStore((state) => state)
  return !isNilOrEmpty(accountId)

  // const sessionQuery = useSession()
  // const accessToken = sessionQuery.data?.accessToken

  // return useMemo(() => {
  //   return !!accessToken && !!accountId
  // }, [accessToken, accountId])
}
