import { Flex } from '@radix-ui/themes'
import styles from './no-data.module.css'

interface NoDataProps {
  title: string
  children: React.ReactNode
}

export function NoData({ title, children }: NoDataProps) {
  return (
    <Flex direction="column" gap="2" justify="center" className={styles.noData}>
      <h3 className="text-5 text-extrabold">{title}</h3>
      <div className="text-2">{children}</div>
    </Flex>
  )
}
