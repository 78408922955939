import type {
  CommonApiDtOsSurveyAccountSurveyDto,
  CoreDtOsAccountAccountSurveyOverviewDtoSurvey,
} from '@/api/models'
import { routing } from '@/i18n/routing'

export function getDefaultSurveyLanguage(
  survey:
    | CommonApiDtOsSurveyAccountSurveyDto
    | CoreDtOsAccountAccountSurveyOverviewDtoSurvey
    | undefined,
) {
  const surveyLanguages = survey?.surveyLanguages ?? []
  const language = surveyLanguages.find((language) => language.isDefault)
  return language?.languageName?.languageCode ?? routing.defaultLocale
}
