import { LinkButton } from '@/components/ui/link-button'
import { clsx } from 'clsx'
import styles from './guide-teaser.module.css'

interface Props {
  buttonLabel: string
  description: string
  href: string
  title: string
}

export function GuideTeaser({ buttonLabel, description, href, title }: Props) {
  return (
    <div className={styles.wrapper}>
      <h2 className={clsx(styles.title, 'text-5')}>{title}</h2>

      <div className={styles.content}>
        <p className={clsx(styles.description, 'text-2')}>{description}</p>

        <div className={styles.buttonWrapper}>
          <LinkButton
            variant="outline"
            href={href}
            target="_blank"
            rel="noreferrer"
          >
            {buttonLabel}
          </LinkButton>
        </div>
      </div>
    </div>
  )
}
