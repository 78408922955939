// @ts-nocheck
export const CoreEnumsBiMembershipTypeEnum = {
  Unknown: 'Unknown',
  FullTimeMember: 'FullTimeMember',
  FlexMember: 'FlexMember',
  Other: 'Other',
  ActiveGreenfee: 'ActiveGreenfee',
  Passive: 'Passive',
  Beginner: 'Beginner',
  Trial: 'Trial',
  Junior: 'Junior',
  LongDistance: 'LongDistance',
  Unsubscribed: 'Unsubscribed',
  Permanent: 'Permanent',
  Custom: 'Custom',
  Former: 'Former',
  LocalMembership: 'LocalMembership',
} as const
export type CoreEnumsBiMembershipTypeEnum =
  (typeof CoreEnumsBiMembershipTypeEnum)[keyof typeof CoreEnumsBiMembershipTypeEnum]
