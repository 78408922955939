export const benchmarkPosition = (
  accountRank: number,
  rankingTotal: number,
) => {
  const percent = Math.ceil((100 / rankingTotal) * accountRank)
  const rankingPercent: number = percent > 50 ? 100 - percent : percent
  const label = percent > 50 ? 'bottom' : 'top'
  return [label, rankingPercent] as const
}

// Convert new lines to paragraph and break tags.
export const nl2br = (str = '') => {
  if (str == null || str === '') {
    return ''
  }
  return `<p>${str.replace(/\r?\n([ \t]*\r?\n)+/g, '</p><p>').replace(/\r?\n/g, '<br />')}</p>`
}

// Convert paragraph and break tags to newlines.
export const br2nl = (html = '') => {
  if (!html) {
    return ''
  }

  return html
    .replace(/<br\s*[/]?>/gi, '\n')
    .replace(/<\/p><p>/g, '\n\n')
    .replace(/<\/?p>/g, '')
}

export const stripHTML = (html = '') => {
  if (!html) {
    return ''
  }

  const htmlReg = /<\/?([a-z][a-z0-9]*)\b[^>]*>?/gi
  return html.replace(htmlReg, '')
}

export const lcFirst = (str = '') => {
  return str.charAt(0).toLowerCase() + str.slice(1)
}

export const ucFirst = (str = '') => {
  if (str == null) {
    return ''
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function hyphenedToCamelCase(str: string) {
  return str.replace(/-([a-z])/gi, (s, group1) => group1.toUpperCase())
}
