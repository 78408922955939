import { isNilOrEmpty } from '@/utils/isNilOrEmpty'
import { MagnifyingGlass, X } from '@phosphor-icons/react/dist/ssr'
import clsx from 'clsx'
import type { SearchFieldProps, ValidationResult } from 'react-aria-components'
import { Button, SearchField as RACSearchField } from 'react-aria-components'
import { Description } from './description'
import { FieldError } from './field-error'
import { Input } from './input'
import { Label } from './label'
import styles from './search-field.module.css'

interface Props extends SearchFieldProps {
  label?: string
  description?: string | React.ReactNode
  placeholder?: string
  errorMessage?: string | ((validation: ValidationResult) => string)
  ref?: React.Ref<HTMLInputElement>
}

export function SearchField({
  className,
  label,
  description,
  errorMessage,
  ...props
}: Props) {
  return (
    <RACSearchField className={clsx(styles.searchField, className)} {...props}>
      {!isNilOrEmpty(label) && <Label>{label}</Label>}
      <MagnifyingGlass className={styles.icon} size="16" />
      <Input className={styles.input} />
      <Button className={styles.clearButton}>
        <X />
      </Button>
      {description && <Description>{description}</Description>}
      <FieldError>{errorMessage}</FieldError>
    </RACSearchField>
  )
}
