export function getPercentFormatter(
  locale: string | undefined,
  options?: Intl.NumberFormatOptions,
) {
  const percentFormatter = new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: options?.minimumFractionDigits ?? 0,
    maximumFractionDigits: options?.maximumFractionDigits ?? 0,
  })

  return percentFormatter
}
