import { Dialog } from '@/components/ui/dialog'
import { ListBox, ListBoxItem } from '@/components/ui/list-box'
import { Popover } from '@/components/ui/popover'
import { DotsThree } from '@phosphor-icons/react/dist/ssr'
import { useTranslations } from 'next-intl'
import React from 'react'
import { Button, DialogTrigger } from 'react-aria-components'

interface Props {
  children: React.ReactNode
}

export function ContextMenu({ children }: Props) {
  const commonT = useTranslations('common')

  if (
    React.Children.toArray(children).filter((child) =>
      React.isValidElement(child),
    ).length === 0
  ) {
    return
  }

  return (
    <DialogTrigger>
      <Button aria-label={commonT('actions')}>
        <DotsThree size="24" />
      </Button>
      <Popover>
        {/*
        <OverlayArrow>
          <svg width={12} height={12} viewBox="0 0 12 12">
            <path d="M0 0 L6 6 L12 0" />
          </svg>
        </OverlayArrow>
        */}
        <Dialog>
          <ListBox aria-label={commonT('actions')}>{children}</ListBox>
        </Dialog>
      </Popover>
    </DialogTrigger>
  )
}

export const ContextMenuItem = ListBoxItem
