import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@/components/common/table'
import { TableColumnFilter } from '@/components/common/table-column-filter'
import { TablePagination } from '@/components/common/table-pagination'
import { CaretDown, CaretUp } from '@phosphor-icons/react/dist/ssr'
import { Flex } from '@radix-ui/themes'
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  type ColumnDef,
  type ColumnFiltersState,
  type ColumnSort,
  type ExpandedState,
  type FilterFn,
  type PaginationState,
  type Row,
  type SortingState,
} from '@tanstack/react-table'
import clsx from 'clsx'
import type { CSSProperties } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDebounce, useWindowSize } from 'react-use'
import styles from './rich-table.module.css'

interface Props<T> {
  columns: ColumnDef<T, any>[]
  data: T[]
  minHeight?: number
  noData?: React.ReactNode
  pageSize?: number
  resetFilters?: boolean
  searchQuery?: string
  sortBy?: ColumnSort[]
  onRowClick?: (row: Row<T>) => void
  onRowsChange?: (rows: Row<T>[]) => void
}

const multiFilter: FilterFn<any> = (row, columnId, filterValues: string[]) => {
  const columnValue = row.getValue(columnId) as string
  return filterValues.length === 0 || filterValues.includes(columnValue)
}

export function RichTable<T>({
  data,
  columns,
  minHeight = 350,
  noData,
  pageSize = 9999,
  resetFilters = false,
  searchQuery = '',
  sortBy,
  onRowClick,
  onRowsChange,
}: Props<T>) {
  const tableWrapRef = useRef<HTMLDivElement>(null)
  const tableRef = useRef<HTMLTableElement>(null)
  const overflowColor = 'var(--gray-1)'
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState(searchQuery)
  const [sorting, setSorting] = useState<SortingState | undefined>(sortBy)
  const [overflows, setOverflows] = useState(false)
  const { width } = useWindowSize()
  const [expanded, setExpanded] = useState<ExpandedState>({})
  const [{ pageIndex }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize,
  })

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  )

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      multi: multiFilter,
    },
    state: {
      columnFilters,
      expanded,
      globalFilter,
      sorting,
      pagination,
    },
    autoResetPageIndex: true,
    enableMultiRowSelection: false,
    enableMultiSort: false,
    enableSortingRemoval: false,
    onColumnFiltersChange: setColumnFilters,
    onExpandedChange: setExpanded,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // @ts-expect-error: unsure how to type this
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // @ts-expect-error: unsure how to type this
    getSubRows: (row) => row.subRows,
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  })

  const filteredRows = table.getFilteredRowModel().rows

  useDebounce(() => setGlobalFilter(searchQuery.trim()), 500, [searchQuery])

  useEffect(() => {
    if (resetFilters) {
      table.resetColumnFilters()
      table.resetGlobalFilter()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFilters])

  useEffect(() => {
    if (onRowsChange != null) {
      onRowsChange(filteredRows)
    }
  }, [filteredRows, onRowsChange])

  useEffect(() => {
    const tableWidth = tableRef.current?.clientWidth
    const tableWrapWidth = tableWrapRef.current?.clientWidth
    if (tableWidth && tableWrapWidth) {
      setOverflows(tableWrapWidth < tableWidth)
    }
  }, [columns, data, width])

  return (
    <div className={styles.wrap}>
      <div
        ref={tableWrapRef}
        className={clsx(
          styles.tableWrap,
          overflows && styles.tableWrapOverflowing,
        )}
        style={
          {
            '--overflow-color': overflowColor,
            minHeight: `${minHeight}px`,
          } as CSSProperties
        }
      >
        <table ref={tableRef} className={styles.table}>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const isActions = header.column.id === 'actions'

                    return (
                      <TableCell
                        key={header.id}
                        heading
                        align={
                          isActions
                            ? 'right'
                            : header.column.columnDef.meta?.align
                        }
                        isSmall={isActions}
                        minWidth={header.column.columnDef.meta?.minWidth}
                        noWrap={header.column.columnDef.meta?.noWrap}
                        style={header.column.columnDef.meta?.style}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: clsx(
                                styles.titleWrapper,
                                header.column.getCanSort() &&
                                  styles.titleWrapperSortable,
                              ),
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}

                            {/* Add a sort direction indicator */}
                            {header.column.getCanSort() && (
                              <Flex
                                direction="column"
                                className={clsx(
                                  styles.sortIconWrapper,
                                  header.column.getIsSorted() &&
                                    styles.sortIconWrapperActive,
                                )}
                              >
                                <CaretUp
                                  size="12"
                                  weight="bold"
                                  className={clsx(
                                    'transition-opacity',
                                    styles.sortIcon,
                                    header.column.getIsSorted() &&
                                      header.column.getIsSorted() === 'asc' &&
                                      styles.sortIconActive,
                                  )}
                                />
                                <CaretDown
                                  size="12"
                                  weight="bold"
                                  className={clsx(
                                    'transition-opacity',
                                    styles.sortIcon,
                                    header.column.getIsSorted() &&
                                      header.column.getIsSorted() === 'desc' &&
                                      styles.sortIconActive,
                                  )}
                                />
                              </Flex>
                            )}
                          </div>
                        )}

                        {/* Filters */}
                        {header.column.getCanFilter() && (
                          <div className={styles.filter}>
                            <TableColumnFilter
                              column={header.column}
                              table={table}
                            />
                          </div>
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableHead>

          <TableBody>
            {table.getRowModel().rows.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  hasRowHover={onRowClick != null}
                  isActive={row.getIsSelected()}
                  onRowClick={() =>
                    onRowClick != null && onRowClick(row as Row<T>)
                  }
                >
                  {row.getVisibleCells().map((cell) => {
                    const isActions = cell.column.id === 'actions'
                    return (
                      <TableCell
                        key={cell.id}
                        align={
                          isActions
                            ? 'right'
                            : cell.column.columnDef.meta?.align
                        }
                        isSmall={isActions}
                        minWidth={cell.column.columnDef.meta?.minWidth}
                        noWrap={cell.column.columnDef.meta?.noWrap}
                        style={cell.column.columnDef.meta?.style}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </table>

        <TablePagination
          canNextPage={table.getCanNextPage()}
          canPreviousPage={table.getCanPreviousPage()}
          pageIndex={table.getState().pagination.pageIndex}
          pageSize={table.getState().pagination.pageSize}
          totalItems={filteredRows.length}
          previousPage={() => table.previousPage()}
          nextPage={() => table.nextPage()}
        />
      </div>

      {!!noData && <div className={styles.noData}>{noData}</div>}
    </div>
  )
}
