'use client'

import { createContext, useContext, useRef } from 'react'
import { useStore, type StoreApi } from 'zustand'

import {
  createContextStore,
  initContextStore,
  type ContextStore,
} from '@/stores/context-store'

export const ContextStoreContext = createContext<StoreApi<ContextStore> | null>(
  null,
)

export interface ContextStoreProviderProps {
  accountId: string | undefined
  children: React.ReactNode
  directAccessAccountId: string | undefined
}

export const ContextStoreProvider = ({
  accountId,
  children,
  directAccessAccountId,
}: ContextStoreProviderProps) => {
  const storeRef = useRef<StoreApi<ContextStore>>(null)
  if (!storeRef.current) {
    storeRef.current = createContextStore(
      initContextStore({ accountId, directAccessAccountId, isOpen: false }),
    )
  }

  return (
    <ContextStoreContext.Provider value={storeRef.current}>
      {children}
    </ContextStoreContext.Provider>
  )
}

export const useContextStore = <T,>(
  selector: (store: ContextStore) => T,
): T => {
  const counterStoreContext = useContext(ContextStoreContext)

  if (!counterStoreContext) {
    throw new Error(`useContextStore must be use within ContextStoreProvider`)
  }

  return useStore(counterStoreContext, selector)
}
