import { isNilOrEmpty } from '@/utils/isNilOrEmpty'
import { Flex } from '@radix-ui/themes'
import { clsx } from 'clsx'
import React from 'react'
import type {
  RadioGroupProps as RACRadioGroupProps,
  RadioProps as RACRadioProps,
  ValidationResult,
} from 'react-aria-components'
import {
  Radio as RACRadio,
  RadioGroup as RACRadioGroup,
} from 'react-aria-components'
import { Description } from './description'
import { FieldError } from './field-error'
import { Label } from './label'
import styles from './radio-group.module.css'

interface RadioGroupProps extends Omit<RACRadioGroupProps, 'children'> {
  children?: React.ReactNode
  label?: string
  description?: string | React.ReactNode
  errorMessage?: string | ((validation: ValidationResult) => string)
}

interface RadioProps extends RACRadioProps {
  children?: React.ReactNode
}

export function RadioGroup({
  label,
  description,
  errorMessage,
  children,
  ...props
}: RadioGroupProps) {
  return (
    <RACRadioGroup {...props} className={styles.radioGroup}>
      {!isNilOrEmpty(label) && <Label>{label}</Label>}
      {children}
      {description && <Description>{description}</Description>}
      <FieldError>{errorMessage}</FieldError>
    </RACRadioGroup>
  )
}

export function Radio({ children, ...props }: RadioProps) {
  return (
    <RACRadio {...props} className={clsx(styles.radio, 'transition-colors')}>
      {children}
    </RACRadio>
  )
}

export function RadioBody({
  children,
  title,
}: {
  children?: React.ReactNode
  title: string
}) {
  return (
    <Flex gap="1" direction="column">
      <div className={styles.radioTitle}>{title}</div>
      {children != null && (
        <div className={styles.radioDescription}>{children}</div>
      )}
    </Flex>
  )
}
