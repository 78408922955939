import type { DateTimePreset } from '@/i18n/formats'
import { formats } from '@/i18n/formats'

interface Props {
  locale: string | undefined
  preset?: DateTimePreset
  timeZone: string
}

export function getDateFormatter({
  locale,
  preset = 'dayMonthYearWithTime',
  timeZone,
}: Props) {
  const options = formats.dateTime?.[preset]
  return Intl.DateTimeFormat(locale, {
    ...options,
    timeZone,
  })
}
