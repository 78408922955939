import { routing } from '@/i18n/routing'

export const LANG_DA_DK = 'da-DK'
export const LANG_DE_DE = 'de-DE'
export const LANG_EN_AU = 'en-AU'
export const LANG_EN_CA = 'en-CA'
export const LANG_EN_GB = 'en-GB'
export const LANG_EN_US = 'en-US'
export const LANG_ES_ES = 'es-ES'
export const LANG_FI_FI = 'fi-FI'
export const LANG_FR_FR = 'fr-FR'
export const LANG_IS_IS = 'is-IS'
export const LANG_JA_JP = 'ja-JP'
export const LANG_NB_NO = 'nb-NO'
export const LANG_NL_NL = 'nl-NL'
export const LANG_RU_RU = 'ru-RU'
export const LANG_SV_FI = 'sv-FI'
export const LANG_SV_SE = 'sv-SE'
export const LANG_ZH_CN = 'zh-Hans-CN'
export const defaultTimeZone = 'Europe/Copenhagen'
export const FALLBACK_WEEK_STARTS_ON = 1 as 0 | 1 | 2 | 3 | 4 | 5 | 6

export type Locale = (typeof routing.locales)[number]
