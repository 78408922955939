import * as Sentry from '@sentry/nextjs'

type Primitive = number | string | boolean | bigint | symbol | null | undefined

export function logError(
  description: string,
  error: unknown = undefined,
  extra?: { [id: string]: Primitive },
): void {
  const errorString: string = error == null ? 'none' : error.toString()
  const message = `${description}: ${errorString}`
  console.error(message)
  if (error != null) {
    Sentry.withScope((scope) => {
      if (extra != null) {
        Object.entries(extra).forEach(([key, value]) => {
          scope.setExtra(key, value)
        })
      }
      Sentry.captureException(error)
    })
  } else {
    Sentry.captureMessage(description)
  }
}
