import { clsx } from 'clsx'
import type { InputProps } from 'react-aria-components'
import { Input as RACInput } from 'react-aria-components'
import styles from './input.module.css'

export function Input({ className, ...props }: InputProps) {
  return (
    <RACInput
      {...props}
      className={clsx(className, styles.input, 'transition-colors')}
    />
  )
}
