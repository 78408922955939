import clsx from 'clsx'
import type { TextProps } from 'react-aria-components'
import { Text } from 'react-aria-components'
import styles from './description.module.css'

export function Description({ ...props }: TextProps) {
  return (
    <Text
      slot="description"
      {...props}
      className={clsx(styles.description, 'text-1')}
    />
  )
}
