export const DATA_COLLECTION_CANCELLED = 'data-collection-cancelled'
export const DATA_COLLECTION_CREATED = 'data-collection-created'
export const EXPORT_COMMENTS = 'export-comments'
export const EXPORT_RESULTS_PPTX = 'export-results-pptx'
export const EXPORT_RESULTS_EXCEL = 'export-results'
export const EXPORT_MAO_RESULTS_EXCEL = 'export-mao-results-excel'
export const EXPORT_SPONSORS = 'export-sponsors'
export const EXPORT_VOLUNTEERS = 'export-volunteers'
export const SUBSCRIPTION_CANCELLED = 'subscription-cancelled'
export const SUBSCRIPTION_CREATED = 'subscription-created'
export const SUBSCRIPTION_RESUBSCRIBED = 'subscription-resubscribed'
export const TOURNAMENT_DATA_COLLECTION_CREATED =
  'tournament-data-collection-created'
export const TOURNAMENT_SCHEDULED = 'tournament-scheduled'
