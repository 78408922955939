import type { ReactNode } from 'react'
import styles from './page-header-left.module.css'

interface Props {
  children: React.ReactNode
  icon?: React.ReactNode
  links?: React.ReactNode
  text?: string | ReactNode
  title: string
}

export function PageHeaderLeft({ children, icon, links, text, title }: Props) {
  return (
    <div className={styles.header}>
      {icon != null && icon}
      <div className={styles.itemFirst}>
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{text}</div>
        {links}
      </div>

      {children != null && <div className={styles.itemLast}>{children}</div>}
    </div>
  )
}
