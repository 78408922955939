import {
  getV1ContextsMeQueryKey,
  getV1TournamentsDataCollectionsQueryKey,
  useDeleteV1TournamentsDataCollectionsDatacollectionid,
  usePostV1TournamentsEventsSchedule,
} from '@/api/client-hooks'
import type { CoreDtOsTournamentTournamentOverviewDto } from '@/api/models'
import { useQueryClient } from '@tanstack/react-query'

export function usePostEventsSchedule() {
  const queryClient = useQueryClient()
  const dataCollectionsQueryKey = getV1TournamentsDataCollectionsQueryKey()

  return usePostV1TournamentsEventsSchedule({
    mutation: {
      onMutate: () => {
        queryClient.cancelQueries({ queryKey: dataCollectionsQueryKey })

        const previousValue =
          queryClient.getQueryData<CoreDtOsTournamentTournamentOverviewDto>(
            dataCollectionsQueryKey,
          )

        if (previousValue != null) {
          queryClient.setQueryData<CoreDtOsTournamentTournamentOverviewDto>(
            dataCollectionsQueryKey,
            (oldValue) => {
              if (oldValue == null || oldValue.trialsLeft == null) {
                return oldValue
              }

              return {
                ...oldValue,
                trialsLeft: oldValue.trialsLeft - 1,
              }
            },
          )
        }

        return previousValue
      },
      // On failure, roll back to the previous value.
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData(dataCollectionsQueryKey, previousValue)
      },
      // On success update the cache with the returned value.
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: dataCollectionsQueryKey })
      },
    },
  })
}

export function useDeleteDataCollection() {
  const queryClient = useQueryClient()
  const dataCollectionsQueryKey = getV1TournamentsDataCollectionsQueryKey()

  return useDeleteV1TournamentsDataCollectionsDatacollectionid({
    mutation: {
      onMutate: ({ dataCollectionId }) => {
        queryClient.cancelQueries({ queryKey: dataCollectionsQueryKey })

        const previousValue =
          queryClient.getQueryData<CoreDtOsTournamentTournamentOverviewDto>(
            dataCollectionsQueryKey,
          )

        if (previousValue != null) {
          queryClient.setQueryData<CoreDtOsTournamentTournamentOverviewDto>(
            dataCollectionsQueryKey,
            (oldValue) => {
              if (oldValue == null) {
                return oldValue
              }

              return {
                ...oldValue,
                dataCollections: oldValue.dataCollections.filter(
                  (dataCollection) =>
                    dataCollection.dataCollectionId !== dataCollectionId,
                ),
              }
            },
          )
        }

        return previousValue
      },
      // On failure, roll back to the previous value.
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData(dataCollectionsQueryKey, previousValue)
      },
      // On success update the cache with the returned value.
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: dataCollectionsQueryKey })

        const contextMeQueryKey = getV1ContextsMeQueryKey()
        queryClient.invalidateQueries({ queryKey: contextMeQueryKey })
      },
    },
  })
}
