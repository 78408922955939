// @ts-nocheck
export const CoreEntitiesSurveyDataCollectionLogTypeEnum = {
  UpdatePausedAt: 'UpdatePausedAt',
  UpdateBookingResource: 'UpdateBookingResource',
  UpdateQuarantine: 'UpdateQuarantine',
  UpdateDelay: 'UpdateDelay',
  UpdateSchedule: 'UpdateSchedule',
  UpdateBookingType: 'UpdateBookingType',
  UpdateDisableBookingResourcesByDefault:
    'UpdateDisableBookingResourcesByDefault',
  UpdateDisableBookingTypesByDefault: 'UpdateDisableBookingTypesByDefault',
} as const
export type CoreEntitiesSurveyDataCollectionLogTypeEnum =
  (typeof CoreEntitiesSurveyDataCollectionLogTypeEnum)[keyof typeof CoreEntitiesSurveyDataCollectionLogTypeEnum]
